const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://vivazmovile.com' : 'http://192.168.100.106:3025',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://vivazmovile.com' : 'http://192.168.100.106:3025',
    api: 'api/',
    apiSocket: 'vivaz::1.2',
    nameDir: 'vivaz',
    package: 'com.vivazmovile.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyCZzEs2XbqkvcAGs4Zwr-mAvgaFaxHFcx4',
    appName: 'Vivaz',
    provider: '',
    colorPrimary: '#94C10C',
    colorDark: '#0A105E',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'com.vivazmovile.passenger',
    playStoreDriverId: 'com.vivazmovile.driver',
    appStorePassengerId: '6636564445',
    appStoreDriverId: '6636564385',
    email: "radiomovilvivaz@gmail.com",
};
export default config;
